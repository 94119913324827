export default {
  page: {
    title: 'Orderbook tool',
  },

  common: {
    cancel: 'Cancel',
    close: 'Close',
    ok: 'Ok',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    customer: 'Customer',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    orderId: 'Order id',
    orderNumber: 'Order number',
    billableArea: 'Billable area',
    createDate: 'Creation date',
    description: 'Description',
    property: 'Property',
    parcel: 'Parcel',
    parcels: 'Parcels',
    projectId: 'Project id',
    parcelNumber: 'Parcel number',
    ha: 'ha',
    status: 'Status',
    orderStatus: 'Order status',
    notes: 'Notes',
    billed: 'Billed',
    routeId: 'Route id',
    source: 'Source',
    geometryWKT: 'Geometry as a WKT string (WGS84)',
    agent: 'Agent',
    startDate: 'Start date',
    start: 'Start',
    restart: 'Restart',
    jobId: 'Job id',
    propertyId: 'Property id',
    firstScanDate: 'First scan date',
    crs: 'CRS',
    projectNumber: 'Project number',
    projects: 'Projects',
    species: 'Species',
    crownRadius: 'Crown radius',
    treeHeight: 'Tree height',
    duration: 'Duration',
    projectStatus: 'Project status',
    plannedUavScans: 'Planned UAV scans',
    plannedBackpackScans: 'Planned backpack scans',
    uav: 'UAV',
    backpack: 'Backpack',
    unplanned: 'Unplanned',
    planned: 'Planned',
    uploaded: 'Uploaded',
    preProcessed: 'Preprocessed',
    postProcessed: 'Post-processed',
    processed: 'Processed',
    progress: 'Progress',
    enabled: 'Enabled',
    developer: 'Developer',
    orderbook: 'Orderbook',
    properties: 'Properties',
    plans: 'Plans',
    plan: 'Plan',
    planId: 'Plan ID',
    remove: 'Remove',
    users: 'Users',
    downloadCompartments: 'Download parcel compartments GeoJSON',
    downloadTrees: 'Download trees GeoJSON',
    downloadParcelKml: 'Download parcel geometry as KML file',
  },

  navigation: {
    projects: 'Projects',
    parcels: 'Parcels',
    customers: 'Customers',
    orders: 'Orders',
    routes: 'Routes',
    pipelines: 'Pipelines',
    uavScans: 'UAV Scans',
    backpackScans: 'Backpack Scans',
    treeEditor: 'Tree Editor',
    plannerUsers: 'Planner Users',
    pointcloudSliceReview: 'Point cloud slice review',
    evaluation: 'Evaluation',
    plans: 'Plans',
  },

  table: {
    fallback: {
      nothingToDisplay: 'Nothing to display',
    },
  },

  dialog: {
    trigger: {
      createCustomer: 'Create new customer',
      createOrder: 'Create new order',
      createParcel: 'Create new parcel',
      createRoute: 'Create new route',
      createNote: 'Create new note',
      createProject: 'Create new project',
    },

    title: {
      createCustomer: 'Create a new customer',
      updateCustomer: 'Update customer',
      createOrder: 'Create a new order',
      updateOrder: 'Update order',
      createParcel: 'Create a new parcel',
      updateParcel: 'Update parcel',
      updateProject: 'Update project',
      createRoute: 'Create a new route',
      updateRoute: 'Update route',
      createNote: 'Create a new note',
      notes: 'Notes of parcel {{parcel}}',
      createJob: 'Create a new job',
      createProject: 'Create a new project',
    },

    description: {
      createJob: 'You are about to create a new job.',
    },
  },

  filter: {
    checkbox: {
      fitBounds: 'Fit the map to a bounding box of filtered parcels',
    },
  },

  maps: {
    control: {
      showTrunksLayer: 'Show trunks layer',
    },
  },

  editor: {
    hints: {
      title: 'Tree editor tool',
      addTree: 'Add new tree',
      saveTree: 'Save recently added tree',
      removeTree: 'Remove tree',
      markGeohash: 'Mark geohash as skipped or processed',
      navigateNext: 'Mark geohash as processed and navigate to next geohash',
    },

    messages: {
      chooseMapTile: 'Choose a map tile to start.',
      zoomLevelIsLow: 'Zoom level is too low. Zoom in to enable tree editor features.',
      clickToAddTree: 'Click inside selected area to add a tree.',
      clickToRemoveTree: 'Click on any tree inside selected area to remove it.',
      clickToChangeGeohashStatus: 'Click on geohash to mark/unmark it to as skipped or unprocessed.',
    },

    treeConfiguration: {
      title: 'Tree configuration',
    },

    displayRemovedTrees: 'Display removed trees (marked with black color)',
  },

  validation: {
    scansNumber: 'Value should be a non-negative number without fractional component',
  },

  status: {
    planned: 'Planned',
    uploaded: 'Uploaded',
    slammed: 'Slammed',
    preProcessed: 'Pre-processed',
    postProcessed: 'Post-processed',
  },

  ssao: {
    label: 'Depth perception mode',
    mode: {
      strong: 'strong',
      normal: 'normal',
      low: 'low',
      none: 'none',
    },
  },

  orderProgress: {
    uavProgress: 'UAV scans progress',
    backpackProgress: 'Backpack scans progress',
  },

  auth: {
    noAccessToOrderbook: `Login failed. User doesn't have access to Orderbook tool.`,
  },
}
