import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useAsyncFn } from 'react-use'
import { useProject } from 'api'
import { createParcelKmlDownload } from 'api/requests'
import { MobileHorizontalScroll, Spinner } from '@project/components'
import { TableIcon } from '../../../../common/TableIcon'
import style from './PipelinesView.module.scss'

export const PipelinesView = ({ dashboard, map }) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const [{ propertyId, parcelNumber }] = useProject(projectId)

  const [{ loading }, downloadParcelKmlFn] = useAsyncFn(async () => {
    const { url } = await createParcelKmlDownload(propertyId, parcelNumber)
    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [propertyId, parcelNumber])

  if (loading) return <Spinner />

  return (
    <div className={style['container']}>
      <div className={style['dashboard']}>
        <MobileHorizontalScroll breakpoint={730} minWidth={730} maxHeight="10000px">
          {dashboard}
        </MobileHorizontalScroll>
      </div>
      <div className={style['map']}>
        <div className={style['map-content']}>{map}</div>
        <div className={style['parcel-download']}>
          {t('common.downloadParcelKml')}&nbsp;
          <TableIcon
            iconTitle={t('common.downloadParcelKml')}
            iconClassName="fa fa-cloud-download-alt"
            onClick={() => downloadParcelKmlFn()}
          />
        </div>
      </div>
    </div>
  )
}
